import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import style from './index.module.css';

export default function CreateResource() {
  const navigate = useNavigate();
  const navigator = useCallback((path) => navigate(path), [navigate]);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user == null) {
      navigator('/login?back-to=/create-resource');
    }
  }, [navigator]);

  return (
    <div className='cyan-background-container'>
      <div className={style.module}>
        <h1>让我们来创建一个新的资源吧！</h1>
        <h3>
          您可以自由决定该资源的属性和内容，也可以决定该资源的可见范围哦！
        </h3>
      </div>
      <div className={style.module}>
        <h5>资源执行标准：R23.4</h5>
      </div>
      <div className={style.module}>
        <p>注意！只有当你写完所有 10 项基础信息之后才会保存哦！</p>
        <a
          className="rounded-button"
          style={{border: '3px solid white'}}
          href="/create-resource/basic-info"
        >
          我知道啦，继续！
        </a>
      </div>
    </div>
  );
}
