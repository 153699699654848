import { React, useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import style from './index.module.css';
import { serverUrl } from '../../constants.jsx';
import { connect } from 'react-redux';
import { setUser } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';

function Login({ setUser }) {
  const usernameRef = useRef(null);
  const codeRef = useRef(null);
  const timerIdRef = useRef(null);

  const navigator = useNavigate();
  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const backTo = params.get('back-to');

  const [emptyUsernameState, setEmptyUsernameState] = useState(false);
  const [emptyCodeState, setEmptyCodeState] = useState(false);
  const [loginErrorMessageState, setLoginErrorMessageState] = useState('');
  let [remainingSecondsState, setRemainingSecondsState] = useState(60);
  const [codeButtonTextState, setCodeButtonTextState] = useState('发送验证码');
  const [isLoadingState, setIsLoadingState] = useState(false);

  const sendCode = async () => {
    const username = usernameRef.current.value.trim();

    if (username === '') {
      setEmptyUsernameState(true);
      return;
    }

    setCodeButtonTextState('正在发送……');

    const headers = {
      'Content-Type': 'application/json',
    };

    const smsPromise =
      username.indexOf('@') === -1
        ? fetch(serverUrl + '/verification-code/send-sms-code', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ PhoneNumber: username }),
          })
        : Promise.resolve(null); // 如果是email，不发送短信

    const emailPromise =
      username.indexOf('@') !== -1
        ? fetch(serverUrl + '/verification-code/send-email-code', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ EmailAddress: username }),
          })
        : Promise.resolve(null); // 如果是手机号，不发送邮件

    try {
      const [smsResponse, emailResponse] = await Promise.all([
        smsPromise,
        emailPromise,
      ]);

      const smsError = smsResponse ? smsResponse.error : null;
      const emailError = emailResponse ? emailResponse.error : null;

      if (smsError == null && emailError == null) {
        startTimer();
      } else {
        smsError == null ?
        setLoginErrorMessageState(emailError) :
        setLoginErrorMessageState(smsError);
        setCodeButtonTextState('发送验证码');
      }
    } catch (error) {
      // 处理错误
    }
  };

  const startTimer = () => {
    timerIdRef.current = setInterval(() => {
      if (remainingSecondsState > 0) {
        setRemainingSecondsState(remainingSecondsState--);
        setCodeButtonTextState('重新发送(' + remainingSecondsState + ')');
      } else {
        setRemainingSecondsState(60);
        setCodeButtonTextState('发送验证码');
        stopTimer();
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerIdRef.current);
  };

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  const login = async () => {
    setLoginErrorMessageState('');
    setIsLoadingState(true);

    const username = usernameRef.current.value.trim();
    const code = codeRef.current.value.trim();

    if (username === '') {
      setEmptyUsernameState(true);
      setIsLoadingState(false);
    }

    if (code === '') {
      setEmptyCodeState(true);
      setIsLoadingState(false);
    }

    if (username === '' || code === '') {
      return;
    }

    const loginHeaders = {
      'Content-Type': 'application/json',
    };
    const loginRequest = {
      username: username,
      code: code,
    };
    const { data: loginData, error: loginError } = await fetch(
      serverUrl + '/user/login',
      {
        method: 'POST',
        headers: loginHeaders,
        body: JSON.stringify(loginRequest),
      }
    );
    if (loginError == null) {
      const getUserHeaders = {
        'Content-Type': 'application/json',
      };
      const { data: getUserData, error: getUserError } = await fetch(
        serverUrl + '/user/get-user-by-id?userId=' + loginData.userId,
        {
          headers: getUserHeaders,
        }
      );
      if (getUserError == null) {
        setUser({
          userId: loginData.userId,
          token: loginData.accessToken,
          username: getUserData.username,
          nickname: getUserData.nickname,
          avatarOssUrl: getUserData.avatar,
        });
        if (backTo == null) {
          navigator('/user-center');
        } else {
          navigator(backTo);
        }
      }
    } else {
      setLoginErrorMessageState(loginError);
      setIsLoadingState(false);
    }
  };

  return (
    <div className={style.container}>
      <form className="needs-validation" noValidate>
        <div className="mb-3">
          <h2>登录</h2>
        </div>
        <div className="mb-3">
          <input
            ref={usernameRef}
            className="form-control"
            placeholder="电子邮箱地址或手机号码"
            onChange={() => {
              setEmptyUsernameState(false);
            }}
          />
          {emptyUsernameState && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              电子邮箱地址或手机号码不能为空哦！
            </div>
          )}
        </div>
        <div className="mb-3">
          <div className="input-group">
            <input
              ref={codeRef}
              className="form-control"
              placeholder="验证码"
              onChange={() => {
                setEmptyCodeState(false);
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              disabled={codeButtonTextState !== '发送验证码'}
              onClick={sendCode}
            >
              {codeButtonTextState}
            </button>
          </div>
          {emptyCodeState && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              验证码也不能为空哦！
            </div>
          )}
        </div>
        <div className={`${style.buttonArea} mb-3`}>
          {!isLoadingState && (
            <div className="rounded-button" onClick={login}>
              登录
            </div>
          )}
          {isLoadingState && (
            <div className="disabled-button">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        <div style={{ textAlign: 'center' }}>
          {loginErrorMessageState !== '' && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {loginErrorMessageState}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setUser })(Login);
