import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../redux/actions/userActions';
import { serverUrl } from '../../constants';
import LearningResource from './LearningResource';
import style from './index.module.scss';
import CreatedResource from './CreatedResource';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';

function UserCenter({ clearUser }) {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  const [learningResourcesState] = useState([
    {
      resourceId: 1,
      name: '大学英语四级词汇【示例】',
      cover:
        'https://henhaoji-public-development.oss-cn-fuzhou.aliyuncs.com/ResourceCover/819268c1-0054-4038-92fe-7ea12dbf7702.png',
      learnedCount: 0,
      learningCount: 0,
      totalCount: 0,
    },
    {
      resourceId: 2,
      name: '大学英语六级词汇【示例】',
      cover:
        'https://henhaoji-public-development.oss-cn-fuzhou.aliyuncs.com/ResourceCover/e19c4661-211e-485a-a44b-11c5c132f638.png',
      learnedCount: 0,
      learningCount: 0,
      totalCount: 0,
    },
    {
      resourceId: 3,
      name: '高等数学一年级上册高频公式【示例】',
      cover:
        'https://henhaoji-public-staging.oss-cn-fuzhou.aliyuncs.com/ResourceCover/75b5aceb-4b09-4e96-bafa-a5c126c08183.png',
      learnedCount: 0,
      learningCount: 0,
      totalCount: 0,
    },
    {
      resourceId: 4,
      name: '高等数学一年级下册高频公式【示例】',
      cover:
        'https://henhaoji-public-staging.oss-cn-fuzhou.aliyuncs.com/ResourceCover/d49f6fb1-c402-488c-9441-67f77719d5fe.png',
      learnedCount: 0,
      learningCount: 0,
      totalCount: 0,
    },
  ]);
  const [createdResourcesState, setCreatedResourcesState] = useState([]);

  const logout = () => {
    if (window.confirm('确定要退出登录吗？')) {
      clearUser();
      localStorage.removeItem('user');
      navigator('/login');
    }
  };

  const removeCreatedResource = (resourceId) => {
    setCreatedResourcesState(
      createdResourcesState.filter((createResource) => createResource.resourceId !== resourceId)
    );
  };

  const publishCreatedResource = (resourceId) => {
    setCreatedResourcesState((createdResourcesState) =>
      createdResourcesState.map((createResource) => {
        if (createResource.resourceId === resourceId) {
          return { ...createResource, isPublished: true };
        }
        return createResource;
      })
    );
  };

  useEffect(() => {
    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user'))?.token,
      };
      const { data: createdResourceData, error: createdResourceError } = await fetch(
        serverUrl + '/user/created-resources',
        {
          headers: headers,
        }
      );
      if (createdResourceError == null) {
        setCreatedResourcesState(createdResourceData);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-3 col-md-12">
          <div>
            <div>
              <h3>{JSON.parse(localStorage.getItem('user'))?.nickname}</h3>
              <div className={style.username}>用户名：{JSON.parse(localStorage.getItem('user'))?.username}</div>
            </div>
            <div>
              <button type="button" className={`${style.logoutButton} btn btn-danger`} onClick={logout}>
                退出登录
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-12">
          <div>
            <h3>我的资源</h3>
            <h5>正在学习的资源</h5>
            {learningResourcesState.length === 0 && <p className={style.noContent}>还没有正在学习的资源呢。</p>}
            {learningResourcesState.length !== 0 && (
              <div className={style.learningResources}>
                {learningResourcesState.map((learningResource) => {
                  return (
                    <LearningResource
                      key={learningResource.resourceId}
                      name={learningResource.name}
                      cover={learningResource.cover}
                      learnedCount={learningResource.learnedCount}
                      learningCount={learningResource.learningCount}
                      totalCount={learningResource.totalCount}
                    />
                  );
                })}
              </div>
            )}
            <h5>我创建的资源</h5>
            {createdResourcesState.length === 0 && <p className={style.noContent}>还没有创建过资源哦。</p>}
            {createdResourcesState.length !== 0 && (
              <div className={style.createdResources}>
                {createdResourcesState.map((createdResource) => {
                  return (
                    <CreatedResource
                      key={createdResource.resourceId}
                      id={createdResource.resourceId}
                      cover={createdResource.coverOssUrl}
                      name={createdResource.name}
                      version={createdResource.version}
                      totalCount={createdResource.totalItemsCount}
                      userCount={createdResource.userCount}
                      status={createdResource.status}
                      removeCreatedResource={removeCreatedResource}
                      publishCreatedResource={publishCreatedResource}
                    >
                      {createdResource.name}
                    </CreatedResource>
                  );
                })}
              </div>
            )}
            <hr />
            <p className={style.tips}>其他更多功能，请前往很好记 app 查看。</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { clearUser })(UserCenter);
