import React from 'react'
import style from './index.module.css'

export default function PrivacyPolicy() {
  return (
    <div id="privacy-policy" className={style.content}>
      <h2>一句话版本就是……</h2>
      <p>我们不会收集任何同使用本 app 无关的信息（比如你的短信、通话记录），不会要求任何同使用本 app 无关的权限（如打开你的手电筒）。同时，我们会尽最大努力保护你的信息安全。</p>
      <hr />
      <h2>隐私政策</h2>
      <p>本隐私政策旨在说明我们在收集、使用和保护您的个人信息方面的政策和做法。使用我们的应用程序及其配套功能（如创建资源）即表示您同意本隐私政策中的条款。</p>
      <h4>1. 收集的信息</h4>
      <p>当您使用我们的应用程序时，我们可能会收集以下信息：</p>
      <ul>
          <li>注册信息：为使用记忆功能，您需要注册并登录。我们可能会收集您的手机号码或电子邮箱，具体取决于您选择的注册方式。</li>
          <li>学习进度和统计数据：我们会收集并存储您的学习进度、统计数据等信息，以便您可以查看和制定合理的记忆计划。</li>
          <li>设备信息：我们会收集您的设备操作系统信息，以便根据不同操作系统显示一些不同的样式。</li>
          <li>摄像头权限：如果您通过相机设置头像，则我们会调用您的设备的摄像头一拍照。</li>
          <li>相册信息：如果您通过在相册中选择图片作为头像，则我们会收集您选择的指定图片的信息。</li>
          <li>位置信息：当您在社区发表内容时，我们会根据 IP 地址显示中国（包含港澳台）用户的省份信息和国外用户的国家信息。</li>
      </ul>
      <h4>2. 使用信息的目的</h4>
      <p>我们收集这些信息的目的是：</p>
      <ul>
          <li>向您提供个性化的服务和功能。</li>
          <li>帮助您跟踪您的学习进度并提供有关完成日期和记忆计划的预测。</li>
          <li>改进我们的应用程序及其功能。</li>
      </ul>
      <h4>3. 信息共享</h4>
      <p>我们不会与任何第三方共享您的个人信息。</p>
      <h4>4. 保护您的信息</h4>
      <p>我们承诺保护您的个人信息安全。为此，我们将您的数据存储在阿里云服务器上，并使用各项安全保障措施，以确保数据的安全性和可靠性。</p>
      <h4>5. 修改和删除个人信息</h4>
      <p>您可以随时修改您的个人信息，例如头像和昵称。如果您选择注销账户，我们将永久删除与您的账户相关的所有个人信息。</p>
      <h4>6. 付款信息</h4>
      <p>我们的应用程序提供高级功能，需要按月支付订阅费用。关于支付信息的收集和处理，我们会遵循相关法规和支付服务提供商的要求。</p>
      <h4>7. 未成年人</h4>
      <p>我们的应用程序没有针对未成年人的特殊政策。未成年人在使用我们的应用程序时，应在家长或监护人的指导下使用。</p>
      <h4>8. 隐私政策的变更</h4>
      <p>我们可能会不时更新本隐私政策。在我们做出任何重大更改时，我们会在应用程序的社区内以置顶文章的方式通知您。继续使用我们的应用程序即表示您同意这些更改。</p>
      <h4>9. 联系我们</h4>
      <p>如果您对本隐私政策有任何疑问或建议，请随时与我们联系。</p>
    </div>
  )
}
