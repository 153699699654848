import React from 'react'
import style from './index.module.scss'

export default function LearningResource(props) {
  return (
    <div className={style.item}>
      <div>
        <img className={style.cover} src={props.cover} alt={props.name} />
      </div>
      <div>
        <div className={style.name}>{props.name}</div>
        <div className={style.count}>已学习的词条：{props.learnedCount}</div>
        <div className={style.count}>学习中的词条：{props.learningCount}</div>
        <div className={style.count}>资源总词条数：{props.totalCount}</div>
      </div>
    </div>
  )
}
