import React from 'react'
import style from './index.module.css'
import feature1Image from './feature1.png'
import feature2Image from './feature2.png'
import feature3Image from './feature3.png'
import feature4Image from './feature4.png'
import feature5Image from './feature5.png'
import { Link } from 'react-router-dom'

export default function Features() {
  return (
    <div id="functions">
        <div className={style.section}>
            <div className={style.intro}>
                <h2 className={style.text}>资源记忆</h2>
                <p className={style.subtext}>根据艾宾浩斯记忆曲线，科学制定记忆计划。别忘了，你可以一次记忆多套资源哦！</p>
            </div>
            <div className={style.screenshot}>
                <img src={feature1Image} alt="Remember" />
            </div>
        </div>
    
        <div className={style.section}>
            <div className={style.screenshot}>
                <img src={feature2Image} alt="Statistics" />
            </div>
            <div className={style.intro}>
                <h2 className={style.text}>记忆进度统计</h2>
                <p className={style.subtext}>统计各资源的记忆进度，看看过去的自己有多厉害，想想未来的自己会如何继续发光！</p>
            </div>
        </div>
    
        <div className={style.section}>
            <div className={style.intro}>
                <h2 className={style.text}>资源商店</h2>
                <p className={style.subtext}>自带海量资源，任意选择所需！所有资源均有资源版本控制，哪个资源最新，我就用哪个～。</p>
            </div>
            <div className={style.screenshot}>
                <img src={feature3Image} alt="Statistics" />
            </div>
        </div>
    
        <div className={style.section}>
            <div className={style.screenshot}>
                <img src={feature4Image} alt="Statistics" />
            </div>
            <div className={style.intro}>
                <h2 className={style.text}>自制资源上传</h2>
                <p className={style.subtext}>资源商店里没有想要的资源？我的资源只想给我的兄弟闺蜜用？来～！我们自己上传一个！</p>
                <Link className='rounded-button' to='/create-resource'>前往创建</Link>
            </div>
        </div>
    
        <div className={style.section}>
            <div className={style.intro}>
                <h2 className={style.text}>内部社区</h2>
                <p className={style.subtext}>聊学习？聊方法？还是单纯侃大山？来社区！</p>
                <div className={style.screenshot}>
                    <img src={feature5Image} alt="Statistics" />
                </div>
            </div>
        </div>
    </div>
  )
}
