import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';
import { serverUrl } from '../../constants';

import style from './index.module.css';

export default function Publish() {
  const navigator = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resourceId = params.get('resource-id');
  const user = localStorage.getItem('user');

  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  const [error, setError] = useState('检查资源中……');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subPage, setSubPage] = useState('');

  const [userIdsWithEnter, setUserIdsWithEnter] = useState('');
  const [userIdsArray, setUserIdsArray] = useState([]);
  const [nicknamesArray, setNicknamesArray] = useState([]);
  const [userIdPageLoadingButton, setUserIdPageLoadingButton] = useState(-1);

  const [regionValues, setRegionValues] = useState([]);
  const [regionTexts, setRegionTexts] = useState([]);
  const [selectedRegionValues, setSelectedRegionValues] = useState([]);

  const [languageValues, setLanguageValues] = useState([]);
  const [languageTexts, setLanguageTexts] = useState([]);
  const [selectedLanguageValues, setSelectedLanguageValues] = useState([]);

  const goHome = () => {
    navigator('/');
  };

  const isValidUserToken = useCallback(async () => {
    const { error } = await fetch(serverUrl + '/token/info', {
      method: 'POST',
    });
    return error == null;
  }, [fetch]);

  const userTokenAndResourceIdMatched = useCallback(
    async (resourceId) => {
      const { error } = await fetch(serverUrl + '/resource/user-id?resourceId=' + resourceId, {
        method: 'GET',
      });
      return { isSucceed: error == null, error: error == null ? '' : error };
    },
    [fetch]
  );

  const publishToGlobal = async () => {
    if (window.confirm('确定要发布资源吗？资源将会提交审核并在审核通过后发布。')) {
      setIsSubmitting(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
      };
      const request = {
        resourceId: resourceId,
      };
      var { error } = await fetch(serverUrl + '/resource/submit', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(request),
      });
      if (error == null) {
        alert('提交审核成功！');
        navigator('/user-center');
      } else {
        alert(error);
      }

      setIsSubmitting(false);
    }
  };

  const publishToLimitedUsers = async () => {
    if (window.confirm('确定要发布资源吗？资源将会提交审核并在审核通过后发布。')) {
      setIsSubmitting(true);

      const request = {
        resourceId: resourceId,
        userIds: userIdsArray,
        regions: selectedRegionValues,
        languages: selectedLanguageValues,
      };
      const { error } = await fetch(serverUrl + '/resource/set-scope', {
        method: 'POST',
        body: JSON.stringify(request),
      });
      if (error == null) {
        alert('提交审核成功！');
        navigator('/user-center');
      } else {
        alert(error);
      }

      setIsSubmitting(false);
    }
  };

  const getNicknamesByUserIds = async () => {
    setUserIdPageLoadingButton(0);

    if (userIdsArray.length > 0) {
      const { data, error } = await fetch(serverUrl + '/user/get-nicknames-by-ids', {
        method: 'POST',
        body: JSON.stringify(userIdsArray),
      });
      if (data) {
        setNicknamesArray(data);
      } else {
        alert(error);
      }
    }

    setUserIdPageLoadingButton(-1);
  };

  const onRegionChecked = (regionValue) => {
    setSelectedRegionValues((prevSelectedInputs) => {
      // 检查被选中的输入是否已经在数组中，如果是，则移除；否则，添加到数组中
      if (prevSelectedInputs.includes(regionValue)) {
        return prevSelectedInputs.filter((id) => id !== regionValue);
      } else {
        return [...prevSelectedInputs, regionValue];
      }
    });
  };

  const onLanguageChecked = (languageValue) => {
    setSelectedLanguageValues((prevSelectedInputs) => {
      // 检查被选中的输入是否已经在数组中，如果是，则移除；否则，添加到数组中
      if (prevSelectedInputs.includes(languageValue)) {
        return prevSelectedInputs.filter((id) => id !== languageValue);
      } else {
        return [...prevSelectedInputs, languageValue];
      }
    });
  };

  useEffect(() => {
    const checkData = async () => {
      if (resourceId == null) {
        setError('参数丢失，未找到资源编号。');
      } else if (!uuidValidate(resourceId)) {
        setError('参数错误，资源编号格式错误。');
      } else if (user == null) {
        navigator('/login?back-to=/create-resource/publish?resource-id=' + resourceId);
      } else if (!isValidUserToken(user.token)) {
        navigator('/login?back-to=/create-resource/publish?resource-id=' + resourceId);
      } else {
        const { isMatched, error } = await userTokenAndResourceIdMatched(resourceId);
        if (!isMatched) {
          setError(error);
        } else {
          setError('');
        }
      }
    };

    const loadRegionList = async () => {
      const { data, error } = await fetch(serverUrl + '/basic-setting/regions');
      if (error == null) {
        const regionValues = [];
        const regionTexts = [];

        data.forEach((region) => {
          regionValues.push(region.value);
          regionTexts.push(region.text);
        });

        setRegionValues([...regionValues]);
        setRegionTexts([...regionTexts]);
      }
    };

    const loadLanguageList = async () => {
      const { data, error } = await fetch(serverUrl + '/basic-setting/languages');
      if (error == null) {
        const languageValues = [];
        const languageTexts = [];

        data.forEach((language) => {
          languageValues.push(language.value);
          languageTexts.push(language.text);
        });

        setLanguageValues([...languageValues]);
        setLanguageTexts([...languageTexts]);
      }
    };

    checkData();
    loadRegionList();
    loadLanguageList();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="cyan-background-container">
      {error === '' && subPage === '' && (
        <div style={{ textAlign: 'center' }}>
          <h2>最后一步，这套资源将对哪些用户公开？</h2>
          {!isSubmitting && (
            <div className={style.actions}>
              <div
                className={'rounded-button rounded-button-border'}
                onClick={() => {
                  setSubPage('choose');
                }}
              >
                {nicknamesArray.length === 0 ? '对有限用户访问' : '继续添加限制条件'}
              </div>
              {nicknamesArray.length !== 0 && <p>仅限{nicknamesArray.length}位用户访问。</p>}
              {selectedRegionValues.length !== 0 && <p>仅限{selectedRegionValues.length}个地区的用户访问。</p>}
              {selectedLanguageValues.length !== 0 && <p>仅限{selectedLanguageValues.length}种语言的用户访问。</p>}
              {(nicknamesArray.length !== 0 || selectedRegionValues.length !== 0 || selectedLanguageValues.length !== 0) && (
                <div className={'rounded-button rounded-button-border'} onClick={publishToLimitedUsers}>
                  发布
                </div>
              )}
              {nicknamesArray.length === 0 && selectedRegionValues.length === 0 && selectedLanguageValues.length === 0 && (
                <div className={'rounded-button rounded-button-border'} onClick={publishToGlobal}>
                  对全球用户公开
                </div>
              )}
            </div>
          )}
          {isSubmitting && (
            <div className="text-center" style={{ margin: '2rem' }}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>提交中……</div>
            </div>
          )}
        </div>
      )}

      {error === '' && subPage === 'choose' && (
        <div style={{ textAlign: 'center' }}>
          <h3>请添加限制条件</h3>
          <p>可以同时设置多种限制条件哦，满足其中一个条件的用户都可以访问你的资源。</p>
          <div className={style.conditions}>
            <div
              className="rounded-button rounded-button-border"
              onClick={() => {
                setSubPage('userId');
              }}
            >
              根据用户编号决定公开范围
            </div>
            {nicknamesArray.length !== 0 && <p>仅限{nicknamesArray.length}位用户访问。</p>}
            <div
              className="rounded-button rounded-button-border"
              onClick={() => {
                setSubPage('region');
              }}
            >
              根据用户地区决定公开范围
            </div>
            {selectedRegionValues.length !== 0 && <p>仅限{selectedRegionValues.length}个地区的用户访问。</p>}
            <div
              className="rounded-button rounded-button-border"
              onClick={() => {
                setSubPage('language');
              }}
            >
              根据用户语言决定公开范围
            </div>
            {selectedLanguageValues.length !== 0 && <p>仅限{selectedLanguageValues.length}种语言的用户访问。</p>}
          </div>
          <div>
            <div
              className="rounded-button"
              onClick={() => {
                setSubPage('');
              }}
            >
              返回
            </div>
          </div>
        </div>
      )}

      {error === '' && subPage === 'userId' && (
        <div style={{ textAlign: 'center' }}>
          <h3>根据用户编号决定公开范围</h3>

          <div className={style.userIdInputContainer}>
            <textarea
              className="form-control"
              id="userIds"
              rows="4"
              placeholder="请输入允许访问资源的用户的编号，以回车分隔"
              value={userIdsWithEnter}
              onChange={(e) => {
                setUserIdsWithEnter(e.target.value);
                setUserIdsArray(e.target.value.split('\n'));
                setNicknamesArray([]);
              }}
            />
            <textarea
              className="form-control"
              id="nicknames"
              rows={4}
              placeholder="对应的用户昵称将显示在这里供校验"
              value={nicknamesArray.join('\n')}
              disabled
            ></textarea>
          </div>
          <div className={style.actions} style={{ margin: '0.2rem' }}>
            <div
              className="rounded-button"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                if (userIdPageLoadingButton === -1) {
                  if (userIdsArray.length === 1 && userIdsArray[0] === '') {
                    userIdsArray.length = 0;
                  }
                  getNicknamesByUserIds();
                }
              }}
            >
              {userIdPageLoadingButton === 0 ? '校验中……' : '校验用户'}
            </div>
            <div
              className={
                userIdPageLoadingButton === -1
                  ? 'rounded-button rounded-button-border'
                  : 'disabled-button rounded-button-border'
              }
              style={{ margin: '0.2rem' }}
              onClick={() => {
                if (userIdPageLoadingButton === -1) {
                  if (
                    nicknamesArray.indexOf('[用户编号格式错误]') !== -1 ||
                    nicknamesArray.indexOf('[找不到该用户]') !== -1
                  ) {
                    alert('有用户校验失败，请核对后重试哦。');
                    return;
                  }
                  if (userIdsWithEnter.length !== 0 && nicknamesArray.length === 0) {
                    alert('请先校验用户～');
                    return;
                  }

                  setSubPage('choose');
                }
              }}
            >
              完成
            </div>
          </div>
        </div>
      )}

      {error === '' && subPage === 'region' && (
        <div style={{ textAlign: 'center' }}>
          <h3>根据用户地区决定公开范围</h3>
          <p>请在下方勾选可以访问您资源的国家或地区。</p>
          <div className={style.regionLanguageInputContainer}>
            {regionTexts.map((regionText, index) => (
              <div className="form-check" key={`region-checkbox-div-${regionValues[index]}`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={regionValues[index]}
                  id="flexCheckDefault"
                  key={`region-checkbox-${regionValues[index]}`}
                  onChange={() => onRegionChecked(regionValues[index])}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  key={`region-checkbox-text-${regionValues[index]}`}
                >
                  {regionText}
                </label>
              </div>
            ))}
          </div>
          <div>
            <div
              className="rounded-button"
              onClick={() => {
                setSubPage('choose');
              }}
            >
              完成
            </div>
          </div>
        </div>
      )}

      {error === '' && subPage === 'language' && (
        <div style={{ textAlign: 'center' }}>
          <h3>根据用户语言决定公开范围</h3>
          <p>请在下方勾选可以访问您资源的语言区域。</p>
          <div className={style.regionLanguageInputContainer}>
            {languageTexts.map((languageText, index) => (
              <div className="form-check" key={`language-checkbox-div-${languageValues[index]}`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={languageValues[index]}
                  id="flexCheckDefault"
                  key={`language-checkbox-${languageValues[index]}`}
                  onChange={() => onLanguageChecked(languageValues[index])}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  key={`language-checkbox-text-${languageValues[index]}`}
                >
                  {languageText}
                </label>
              </div>
            ))}
          </div>
          <div>
            <div
              className="rounded-button"
              onClick={() => {
                setSubPage('choose');
              }}
            >
              完成
            </div>
          </div>
        </div>
      )}

      {error !== '' && (
        <div style={{ textAlign: 'center' }}>
          <h2>{error}</h2>
          <button className="rounded-button rounded-button-border" onClick={goHome}>
            返回首页
          </button>
        </div>
      )}
    </div>
  );
}
