import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';
import { serverUrl } from '../../constants';

export default function BasicInfoSaved() {
  const navigator = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resourceId = params.get('resource-id');
  const user = localStorage.getItem('user');

  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  const [error, setError] = useState('检查资源中……');

  const goNext = () => {
    navigator('/create-resource/content?resource-id=' + resourceId);
  };

  const goHome = () => {
    navigator('/');
  };

  const isValidUserToken = useCallback( async () => {
    const { error } = await fetch(serverUrl + '/token/info', {
      method: 'POST',
    });
    return error == null;
  }, [fetch]);

  const userTokenAndResourceIdMatched = useCallback(async (resourceId) => {
    const { error } = await fetch(
      serverUrl + '/resource/user-id?resourceId=' + resourceId,
      {
        method: 'GET',
      }
    );
    return { isSucceed: error == null, error: error == null ? '' : error };
  }, [fetch]);

  useEffect(() => {
    const checkData = async () => {
      if (resourceId == null) {
        setError('参数丢失，未找到资源编号。');
      } else if (!uuidValidate(resourceId)) {
        setError('参数错误，资源编号格式错误。');
      } else if (user == null) {
        navigator(
          '/login?back-to=/create-resource/basic-info-saved?resource-id=' +
            resourceId
        );
      } else if (!isValidUserToken(user.token)) {
        navigator(
          '/login?back-to=/create-resource/basic-info-saved?resource-id=' +
            resourceId
        );
      } else {
        const { isMatched, error } = await userTokenAndResourceIdMatched(
          resourceId
        );
        if (!isMatched) {
          setError(error);
        } else {
          setError('');
        }
      }
    };

    checkData();
  }, [isValidUserToken, navigator, resourceId, user, userTokenAndResourceIdMatched]);

  return (
    <div className="cyan-background-container">
      {error === '' && (
        <div style={{ textAlign: 'center' }}>
          <h2>基本信息已保存并上传！</h2>
          <h2>接下来，让我们为你的资源创建内容。</h2>
          <button
            className="rounded-button rounded-button-border"
            onClick={goNext}
          >
            下一步
          </button>
        </div>
      )}
      {error !== '' && (
        <div style={{ textAlign: 'center' }}>
          <h2>{error}</h2>
          <button
            className="rounded-button rounded-button-border"
            onClick={goHome}
          >
            返回首页
          </button>
        </div>
      )}
    </div>
  );
}
