import { useNavigate } from 'react-router-dom';
import { clearUser } from '../redux/actions/userActions';

const useHenhaojiFetch = (dispatch) => {
  const navigator = useNavigate();

  const henhaojiFetch = async (url, options = {headers: null}) => {
    const result = {
      data: null,
      error: null,
    };

    if (
      localStorage.getItem('user') !== null &&
      JSON.parse(localStorage.getItem('user')).token
    ) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('user')).token
        }`,
        'Content-Type':
          options.headers == null || options.headers['Content-Type'] == null
            ? 'application/json'
            : options.headers['Content-Type'],
      };
    } else {
      options.headers = {
        ...options.headers,
        'Content-Type':
          options.headers == null || options.headers['Content-Type'] == null
            ? 'application/json'
            : options.headers['Content-Type'],
      };
    }

    try {
      const response = await fetch(url, options);

      if (response.status === 401) {
        navigator('/login');
        localStorage.removeItem('user');
        dispatch(clearUser());

        const json = await response.json();
        result.error = json.ZhCn ?? '401';
      } else {
        var text = (await response.text()).trim();
        if (text === '') {
          result.data = null;
        } else {
          try {
            const json = JSON.parse(text);
            if (json.ZhCn == null) {
              result.data = json;
            } else {
              result.data = null;
              result.error = json.ZhCn;
            }
          } catch {
            result.data = text;
          }
        }
      }
    } catch (error) {
      result.error = error.message;
    }

    return result;
  };

  return henhaojiFetch;
};

export default useHenhaojiFetch;
