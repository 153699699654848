import React from 'react'
import avatar1Image from './avatar-1.png'
import avatar2Image from './avatar-2.png'
import avatar3Image from './avatar-3.png'
import Comment from './Comment'
import style from './index.module.css'

export default function Comments() {
  const comments = [
    {
      avatarUrl: avatar1Image,
      content: '这个app真的太棒了！非常实用！',
      authorName: '小仙女🧚'
    },
    {
      avatarUrl: avatar2Image,
      content: '我到处在找可以自己上传单词本的 app，但是要么就太丑了，要么就是很难用。直到我发现了很好记！！！',
      authorName: '寂寞无声'
    },
    {
      avatarUrl: avatar3Image,
      content: '设计 yyds！开发者居然还能想到搞一个社区哈哈哈，经常在里面求词书🙇',
      authorName: 'ε-(´∀｀; )'
    }
  ]

  return (
    <div className={style.container}>
      {
        comments.map((comment) => {
          return <Comment key={comment.authorName} avatarUrl={comment.avatarUrl} content={comment.content} authorName={comment.authorName} />
        })
      }
    </div>
  )
}
