import React, { useState, useRef, useImperativeHandle } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview.ts';
import { useDebounceEffect } from './useDebounceEffect.ts';
import 'react-image-crop/dist/ReactCrop.css';
import style from './index.module.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageUploaderCropper = React.forwardRef(
  ({ onDownloadCropClick }, ref) => {
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const aspect = 2 / 3;

    useImperativeHandle(
      ref,
      () => ({
        onDownloadCropClick: onDownloadCropClick,
      }),
      [onDownloadCropClick]
    );    

    const chooseImage = () => {
      const inputImageElement = document.getElementById('choose-image');
      if (inputImageElement) {
        inputImageElement.click();
      }
    };

    function onSelectFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        setCrop(undefined);
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImgSrc(reader.result?.toString() || '')
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    function onImageLoad(e) {
      if (aspect) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
      }
    }

    // eslint-disable-next-line
    async function onDownloadCropClick() {
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist');
      }
    
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
    
      const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );
      const ctx = offscreen.getContext('2d');
      if (!ctx) {
        throw new Error('No 2d context');
      }
    
      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );
    
      const blob = await offscreen.convertToBlob({
        type: 'image/jpeg',
        quality: 0.6,
      });
    
      const reader = new FileReader();
      const base64Promise = new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    
      reader.readAsDataURL(blob);
    
      return base64Promise;
    }    

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop
          );
        }
      },
      100,
      [completedCrop]
    );

    return (
      <div>
        <div
          className="Crop-Controls"
          style={{ textAlign: 'center', marginBottom: '1em' }}
        >
          <label className={style.imageUploadButton} onClick={chooseImage}>
            选择图片
          </label>
          <input
            id="choose-image"
            type="file"
            accept="image/*"
            hidden
            onChange={onSelectFile}
          />
        </div>
        {!!imgSrc && (
          <ReactCrop
            className={style.reactCrop}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <div hidden>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <div>
              <button onClick={onDownloadCropClick}>Download Crop</button>
              <a
                href="#hidden"
                ref={hiddenAnchorRef}
                download
                style={{
                  position: 'absolute',
                  top: '-200vh',
                  visibility: 'hidden',
                }}
              >
                Hidden download
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ImageUploaderCropper;
