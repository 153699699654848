import NavBar from "./components/NavBar";
import { Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Home from "./pages/Home";
import './global.scss'
import Footer from "./components/Footer";
import Resources from "./pages/Resources";
import CreateResource from "./pages/CreateResource";
import BasicInfo from "./pages/BasicInfo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Login from "./pages/Login";
import UserCenter from "./pages/UserCenter";
import BasicInfoSaved from "./pages/BasicInfoSaved";
import Content from "./pages/Content";
import Publish from "./pages/Publish";

function App() {
  return (
    <Provider store={store}>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/create-resource' element={<CreateResource />} />
        <Route path='/create-resource/basic-info' element={<BasicInfo />} />
        <Route path='/create-resource/basic-info-saved' element={<BasicInfoSaved />} />
        <Route path='/create-resource/content' element={<Content />} />
        <Route path='/create-resource/publish' element={<Publish />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/login' element={<Login />} />
        <Route path='/user-center' element={<UserCenter />} />
      </Routes>
      <Footer />
    </Provider>
  );
}

export default App;
