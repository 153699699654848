import React from 'react'
import { Link } from 'react-router-dom'
import style from './index.module.css'

export default function Resources() {
  return (
    <div className={style.content}>
      <h1>我们目前拥有的资源</h1>
      <p>这里还什么都没有呢……</p>
      
      <hr/>
      
      <p>没找到想要的资源吗？</p>
      <Link className='rounded-button' to='/create-resource'>立刻创建！</Link>
    </div>
  )
}
