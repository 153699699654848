import React from 'react';

export default function ModelDialog(props) {
  const contentLines = props.content.split('<br />');

  return (
    <>
      <label
        data-bs-toggle="modal"
        data-bs-target={`#${props.id}`}
        style={{ cursor: 'pointer', color: 'khaki' }}
      >
        {props.label}
      </label>
      <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={`{id}Label`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`{id}Label`}>
                {props.title}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{ color: 'gray' }}>
              {contentLines.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index !== contentLines.length - 1 && <br />} {/* 在每行文本之间插入<br />标签 */}
                </React.Fragment>
              ))}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
