const initState = JSON.parse(localStorage.getItem('user')) || null;

const userReducer = (prevState = initState, action) => {
  switch (action.type) {
    case 'SET_USER':
      localStorage.setItem('user', JSON.stringify(action.payload));
      return action.payload;
    case 'CLEAR_USER':
      localStorage.removeItem('user');
      return null;
    default:
      return prevState;
  }
};

export default userReducer;
