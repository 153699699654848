import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import { serverUrl } from '../../../constants';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../../helpers/fetch';

export default function CreatedResource(props) {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  const edit = () => {
    navigator('/create-resource/content?resource-id=' + props.id);
  };

  const remove = async () => {
    if (window.confirm('确定要删除资源《' + props.name + '》吗？')) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
      };
      var { error } = await fetch(serverUrl + '/resource/' + props.id, {
        method: 'DELETE',
        headers: headers,
      });
      if (error == null) props.removeCreatedResource(props.id);
    }
  };

  return (
    <div className={style.item}>
      <div>
        <img className={style.cover} src={props.cover} alt={props.name} />
      </div>
      <div>
        <div className={style.name}>{props.name}</div>
        <div className={style.count}>版本：{props.version}</div>
        <div className={style.count}>资源总词条数：{props.totalCount}</div>
        <div className={style.count}>当前学习人数：{props.userCount}</div>
        {(() => {
          if (props.status === 0) {
            return <div className={style.count}>未发布</div>;
          } else if (props.status === 1) {
            return <div className={style.count} style={{color: 'orange'}}>已提交审核</div>;
          } else if (props.status === 2) {
            return <div className={style.count} style={{color: 'green'}}>已发布</div>;
          } else if (props.status === 3) {
            return <div className={style.count} style={{color: 'red'}}>被驳回</div>;
          }
        })()}
        <div>
          <span className={style.action} onClick={edit}>
            编辑
          </span>
          <span className={props.userCount === 0 ? style.action : style.actionDisabled} onClick={remove}>
            删除
          </span>
        </div>
      </div>
    </div>
  );
}
