import React from 'react'
import Slogan from './Slogan'
import Features from './Features'
import Comments from './Comments'

export default function Home() {
  return (
    <div>
      <Slogan />
      <Features />
      <Comments />
    </div>
  )
}
