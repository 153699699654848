import React from 'react'
import beianImg from './beian.png';
import style from './index.module.css'

export default function Footer() {
  return (
    <div className={style.footer}>
      <span>© <a href="https://nicotech.cc">睨客软件俱乐部</a> 很好记团队 保留所有权利</span>
      <span>中国陕西省西安市雁塔区建工路 48 号</span>
      <span><a href="mailto:henhaoji@nicotech.cc">henhaoji@nicotech.cc</a></span>
      <span>
        <a href="https://beian.miit.gov.cn">陕ICP备2023006091号</a>
      </span>
      <span>
        <img src={beianImg} alt='备案图标' />
        <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011302001763'>陕公网安备 61011302001763号</a>
      </span>
    </div>
  )
}
