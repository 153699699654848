import { React, useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import ImageUploaderCropper from '../../components/ImageUploaderCropper';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';
import { serverUrl } from '../../constants';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default function BasicInfo() {
  const [offset, setOffset] = useState(0);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mainCategory, setMainCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [, setCoverOssUrl] = useState('');
  const [source, setSource] = useState('');
  const [language, setLanguage] = useState('');
  const [multiChoiceProvided, setMultiChoiceProvided] = useState(false);
  const [multiChoiceAnswerProvided, setMultiChoiceAnswerProvided] = useState(false);
  const [memorizationProvided, setMemorizationProvided] = useState(false);
  const [confusingItemsProvided, setConfusingItemsProvided] = useState(false);
  const [voiceProvided, setVoiceProvided] = useState(false);
  const [voiceNameList, setVoiceNameList] = useState(['']);
  const [spellableAnswerProvided, setSpellableAnswerProvided] = useState(false);
  const [correctAnswerGroupProvided, setCorrectAnswerGroupProvided] = useState(false);
  const [caseSensitiveAnswer, setCaseSensitiveAnswer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const imageUploaderCropperRef = useRef();

  const navigate = useNavigate();
  const navigator = useCallback((path) => navigate(path), [navigate]);
  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user == null) {
      navigator('/login?back-to=/create-resource/basic-info');
      return;
    }

    const loadCategories = async () => {
      const { data, error } = await fetch(serverUrl + '/basic-setting/resource-main-categories');
      if (error !== null) {
        console.error(error);
        return;
      }
      setMainCategories(data);
    };

    loadCategories();

    // eslint-disable-next-line
  }, []);

  const onMultiChoiceProvidedChanged = () => {
    setMultiChoiceProvided(!multiChoiceProvided);
  };

  const onVoiceProvidedChanged = () => {
    setVoiceProvided(!voiceProvided);
  };

  const onSpellableAnswerProvidedChanged = () => {
    setSpellableAnswerProvided(!spellableAnswerProvided);
  };

  const addVoiceName = () => {
    setVoiceNameList([...voiceNameList, '']);
  };

  const removeVoiceName = (index) => {
    const updatedList = [...voiceNameList];
    updatedList.splice(index, 1);
    setVoiceNameList(updatedList);
  };

  const moveToNext = () => {
    setOffset((prevOffset) => prevOffset - 10);
  };

  const moveToPrev = () => {
    setOffset((prevOffset) => prevOffset + 10);
  };

  const getCoverBase64 = async () => {
    return await imageUploaderCropperRef.current.onDownloadCropClick();
  };

  const submit = async () => {
    setIsSubmitting(true);

    const resourceId = uuidv4();
    try {
      const coverBase64 = await getCoverBase64();
      const coverRequest = {
        fileBytes: coverBase64,
        fileName: uuidv4() + '.png',
      };
      const { data: coverOssUrl } = await fetch(serverUrl + '/common/upload-resource-cover', {
        method: 'POST',
        body: JSON.stringify(coverRequest),
      });
      setCoverOssUrl(coverOssUrl);

      const submitRequest = {
        resourceId: resourceId,
        saveDateTime: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
        published: false,
        title: title,
        description: description,
        category: subCategory,
        coverUrl: coverOssUrl,
        source: source,
        language: language,
        multiChoiceProvided: multiChoiceProvided,
        multiChoiceAnswerProvided: multiChoiceAnswerProvided,
        memorizationProvided: memorizationProvided,
        confusingItemsProvided: confusingItemsProvided,
        voiceProvided: voiceProvided,
        voiceNameList: voiceNameList
          .filter((voiceName) => voiceName !== '')
          .map((voiceName) => voiceName.replace(/,/g, '')),
        spellableAnswerProvided: spellableAnswerProvided,
        correctAnswerGroupProvided: correctAnswerGroupProvided,
        caseSensitiveAnswer: caseSensitiveAnswer,
      };
      await fetch(serverUrl + '/resource/basic-info', {
        method: 'POST',
        body: JSON.stringify(submitRequest),
      });

      navigator('/create-resource/basic-info-saved?resource-id=' + resourceId);
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={style.multiStepContainer}>
      <div className={style.stepContainer} style={{ transform: `translateX(${offset}%)` }}>
        <div className={style.stepItem}>
          <p>1/11</p>
          <h2>给资源起一个响亮的名字吧！</h2>
          <p>请给你的资源起一个名字～比如《大学英语四级词汇》《考研数学二公式》</p>
          <input type="text" className="single-line-input" onChange={(e) => setTitle(e.target.value)} />
          <button
            className={
              title.trim() !== '' ? 'rounded-button rounded-button-border' : 'disabled-button rounded-button-border'
            }
            disabled={title.trim() === ''}
            onClick={moveToNext}
          >
            下一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>2/11</p>
          <h2>请简单描述一下这个资源。</h2>
          <p>比如，这个资源包括了大学英语考试第四等级的全部考纲词汇。</p>
          <input type="text" className="single-line-input" onChange={(e) => setDescription(e.target.value)} />
          <button
            className={
              description.trim() !== ''
                ? 'rounded-button rounded-button-border'
                : 'disabled-button rounded-button-border'
            }
            disabled={description.trim() === ''}
            onClick={moveToNext}
          >
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>3/11</p>
          <h2>这个资源属于什么分类？</h2>
          <p>请为资源选择它的分类和子分类。</p>
          <select
            className={style.customSelect}
            defaultValue=""
            onChange={async (e) => {
              setMainCategory(e.target.value);
              const { data, error } = await fetch(
                serverUrl + '/basic-setting/resource-sub-categories/' + e.target.value
              );
              if (error) {
                console.error(error);
              } else {
                setSubCategories(data);
              }
            }}
          >
            <option value="" disabled>
              请选择主分类
            </option>
            {mainCategories.map((item) => (
              <option value={item.value}>{item.text}</option>
            ))}
          </select>
          <select className={style.customSelect} defaultValue="" onChange={(e) => setSubCategory(e.target.value)}>
            <option value="" disabled>
              请选择子分类
            </option>
            {subCategories?.map((item) => (
              <option value={item.value}>{item.text}</option>
            ))}
          </select>
          <button
            className={
              mainCategory !== '' && subCategory !== ''
                ? 'rounded-button rounded-button-border'
                : 'disabled-button rounded-button-border'
            }
            disabled={mainCategory === '' || subCategory === ''}
            onClick={moveToNext}
          >
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>4/11</p>
          <h2>接下来，给你的资源上传一张封面吧！</h2>
          <p>请选择一张宽高比约为 2:3 的图片作为封面。</p>
          <p>请不要选择过大的图片哦！最好不要超过 5MB。</p>
          <ImageUploaderCropper ref={imageUploaderCropperRef} onDownloadCropClick={getCoverBase64} />
          <button className="rounded-button rounded-button-border" onClick={moveToNext}>
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>5/11</p>
          <h2>你的资源来源是……</h2>
          <p>请告知你的资源是来源于哪里。例如，来自 2023 年考研英语考试大纲。</p>
          <input type="text" className="single-line-input" onChange={(e) => setSource(e.target.value)} />
          <button
            className={
              source.trim() !== '' ? 'rounded-button rounded-button-border' : 'disabled-button rounded-button-border'
            }
            disabled={source.trim() === ''}
            onClick={moveToNext}
          >
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>6/11</p>
          <h2>请告知我们你的资源的主要语言。</h2>
          <p>主要语言是指这个资源面向的市场的主要语言。仅可以指定一种语言。</p>
          <select className={style.customSelect} defaultValue="" onChange={(e) => setLanguage(e.target.value)}>
            <option value="" disabled>
              请选择一个语言
            </option>
            <option value="ZhCn">简体中文 - 中国大陆地区</option>
            <option value="ZhHk">繁體中文 - 香港地區</option>
            <option value="ZhMo">繁體中文 - 澳門地區</option>
            <option value="ZhTw">繁體中文 - 台灣地區</option>
            <option value="ZhSg">简体中文 - 新加坡</option>
            <option value="JaJp">日本語</option>
            <option value="EnUs">English - United States</option>
            <option value="EnGb">English - United Kingdom</option>
            <option value="EnAu">English - Australia</option>
            <option value="EnCa">English - Canada</option>
            <option value="EnIn">English - India</option>
            <option value="EnSg">English - Singapore</option>
            <option value="EnNz">English - New Zealand</option>
            <option value="EnZa">English - South Africa</option>
          </select>
          <button
            className={
              language !== '' ? 'rounded-button rounded-button-border' : 'disabled-button rounded-button-border'
            }
            disabled={language === ''}
            onClick={moveToNext}
          >
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>7/11</p>
          <h2>你的资源在学习和复习时包括选择题形式吗？</h2>
          <p>如果包括，则会在学习复习过程中加入包含至少三个选项的选择题。选项也需要手动提供哦！</p>
          <label className={style.customCheckbox}>
            包括选择题形式
            <input type="checkbox" checked={multiChoiceProvided} onChange={onMultiChoiceProvidedChanged} />
            <span className={style.checkmark}></span>
          </label>
          {multiChoiceProvided && (
            <label className={style.customCheckbox}>
              选择题的错误选项会标出对应的正确题目（需随错误选项一起提供）
              <input type="checkbox" onChange={(e) => setMultiChoiceAnswerProvided(e.target.checked)} />
              <span className={style.checkmark}></span>
            </label>
          )}
          <button className="rounded-button rounded-button-border" onClick={moveToNext}>
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>8/11</p>
          <h2>是否为每个项目提供记忆方法？</h2>
          <p>每个项目默认需要提供解析说明，但你是否愿意为每个选项额外提供独特的记忆方法？这样有助于用户更快记忆哦！</p>
          <p>并不是每个项目都必须提供。勾选后，我们会为你留出这一区域，你可自行决定是否填写内容。</p>
          <label className={style.customCheckbox}>
            为每个项目额外提供记忆方法
            <input type="checkbox" onChange={(e) => setMemorizationProvided(e.target.checked)} />
            <span className={style.checkmark}></span>
          </label>
          <button className="rounded-button rounded-button-border" onClick={moveToNext}>
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>9/11</p>
          <h2>是否为每个项目提供与正确答案相近的易混项？</h2>
          <p>
            每个项目默认需要提供解析说明，但你是否愿意为每个选项额外提供与正确答案相近的易混项？这些易混项将一起列在正确答案下方区域。
          </p>
          <p>并不是每个项目都必须提供。勾选后，我们会为你留出这一区域，你可自行决定是否填写内容。</p>
          <label className={style.customCheckbox}>
            为每个项目额外提供与正确答案相近的易混项
            <input type="checkbox" onChange={(e) => setConfusingItemsProvided(e.target.checked)} />
            <span className={style.checkmark}></span>
          </label>
          <button className="rounded-button rounded-button-border" onClick={moveToNext}>
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>10/11</p>
          <h2>是否为每个项目提供题目音频？</h2>
          <p>你是否愿意为每个选项提供题目音频？如果提供，用户在学习复习时题目音频将自动播放。</p>
          <p>可提供不同口音或风格的音频，最多可以提供 10 种。</p>
          <label className={style.customCheckbox}>
            为每个项目提供题目音频
            <input type="checkbox" checked={voiceProvided} onChange={onVoiceProvidedChanged} />
            <span className={style.checkmark}></span>
          </label>
          {voiceProvided && (
            <div>
              <p>请勿在此输入英文逗号（,），如有输入，我们会将其删除。</p>
              <div style={{ textAlign: 'center' }}>
                {voiceNameList.map((_voice, index) => {
                  return (
                    <div>
                      <input
                        type="text"
                        value={voiceNameList[index]}
                        className={style.voiceName}
                        onChange={(e) => {
                          const updatedVoiceList = [...voiceNameList];
                          updatedVoiceList[index] = e.target.value;
                          setVoiceNameList(updatedVoiceList);
                        }}
                      />
                      <button className="square-button" onClick={addVoiceName}>
                        +
                      </button>
                      {voiceNameList.length !== 1 && (
                        <button className="square-button" onClick={() => removeVoiceName(index)}>
                          -
                        </button>
                      )}
                      {voiceNameList.length === 1 && (
                        <button className="square-button" style={{ visibility: 'hidden' }}>
                          -
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <button
            className={
              !voiceProvided || (voiceProvided && !voiceNameList.every((voiceName) => voiceName === ''))
                ? 'rounded-button rounded-button-border'
                : 'disabled-button rounded-button-border'
            }
            disabled={voiceProvided && voiceNameList.every((voiceName) => voiceName === '')}
            onClick={moveToNext}
          >
            下一步
          </button>
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
        <div className={style.stepItem}>
          <p>11/11</p>
          <h2>你的答案支持拼写吗？</h2>
          <p>如果支持拼写，则用户可以在学习或复习时通过主动拼写答案来加强记忆。</p>
          <label className={style.customCheckbox}>
            答案支持拼写
            <input type="checkbox" checked={spellableAnswerProvided} onChange={onSpellableAnswerProvidedChanged} />
            <span className={style.checkmark}></span>
          </label>
          {spellableAnswerProvided && (
            <div style={{ textAlign: 'center' }}>
              <div style={{ marginBottom: '40px' }}>
                <label className={style.customCheckbox}>
                  提供可以接受的正确答案组
                  <input type="checkbox" onChange={(e) => setCorrectAnswerGroupProvided(e.target.checked)} />
                  <span className={style.checkmark}></span>
                </label>
              </div>
              <div style={{ marginBottom: '40px' }}>
                <label className={style.customCheckbox}>
                  严格区分英文大小写
                  <input type="checkbox" onChange={(e) => setCaseSensitiveAnswer(e.target.checked)} />
                  <span className={style.checkmark}></span>
                </label>
              </div>
              <div style={{ padding: '10px', textAlign: 'left' }}>
                <p className={style.explanationText}>
                  正确答案组，意为用户在拼写答案时，如果用户的拼写结果与答案组中任一项目相同，则视为拼写正确。
                </p>
                <p className={style.explanationText}>
                  如不勾选，则用户的拼写必须与答案完全一致才视为拼写正确。英文大小写是否需要一致需要取决于您是否勾选严格区分英文大小写选项。
                </p>
              </div>
            </div>
          )}

          {!isSubmitting && (
            <button className="rounded-button rounded-button-border" onClick={submit}>
              我写完了
            </button>
          )}
          {isSubmitting && (
            <div className="disabled-button">
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            </div>
          )}
          <button className="rounded-button" onClick={moveToPrev}>
            上一步
          </button>
        </div>
      </div>
    </div>
  );
}
