import React from 'react'
import style from './index.module.css'

export default function Comment(props) {
  return (
    <div className={style.card}>
      <img className={style.avatar} src={props.avatarUrl} alt="avatar"/>
      <p className={style.text}>{props.content}</p>
      <p className={style.author}>- {props.authorName}</p>
    </div>
  )
}
