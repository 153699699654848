import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useHenhaojiFetch from '../../helpers/fetch';
import { serverUrl } from '../../constants';
import style from './index.module.css';

import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import moment from 'moment';
// eslint-disable-next-line
import { tz } from 'moment-timezone';
import db from '../../helpers/db';
import ModelDialog from '../../components/ModelDialog';

export default function Content() {
  const navigator = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resourceId = params.get('resource-id');
  const user = localStorage.getItem('user');

  const dispatch = useDispatch();
  const fetch = useHenhaojiFetch(dispatch);

  const [error, setError] = useState('检查资源中……');

  const [resourceTitle, setResourceTitle] = useState('');
  const [savedItemCount, setSavedItemCount] = useState(0);
  const [uploadedItemCount, setUploadedItemCount] = useState(0);
  const [latestUploadDateTime, setLatestUploadDateTime] = useState('');
  const [timeZoneString, setTimeZoneString] = useState('');
  const [multiChoiceProvided, setMultiChoiceProvided] = useState(false);
  const [multiChoiceAnswerProvided, setMultiChoiceAnswerProvided] = useState(false);
  const [memorizationProvided, setMemorizationProvided] = useState(false);
  const [confusingItemsProvided, setConfusingItemsProvided] = useState(false);
  const [voiceProvided, setVoiceProvided] = useState(false);
  const [voiceNameList, setVoiceNameList] = useState(['']);
  const [correctAnswerGroupProvided, setCorrectAnswerGroupProvided] = useState(false);

  const voiceRefs = useRef([]);

  const [title, setTitle] = useState('');
  const [voiceOssUrls, setVoiceOssUrls] = useState([]);
  const [answer, setAnswer] = useState('');
  const [memorization, setMemorization] = useState('');
  const [confusingItemsCount, setConfusingItemsCount] = useState(1);
  const [confusingItems, setConfusingItems] = useState(['']);
  const [confusingItemsAnswers, setConfusingItemsAnswers] = useState(['']);
  const [multiChoiceItemsCount, setMultiChoiceItemsCount] = useState(1);
  const [multiChoiceItems, setMultiChoiceItems] = useState(['']);
  const [multiChoiceItemsAnswer, setMultiChoiceItemsAnswer] = useState(['']);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(1);
  const [correctAnswers, setCorrectAnswers] = useState(['']);
  const [remark, setRemark] = useState('');

  const [contentListItem, setContentListItem] = useState([]);

  const [editStatus, setEditStatus] = useState('add');
  const [loadingButton, setLoadingButton] = useState(-1);

  const goHome = () => {
    navigator('/');
  };

  const goPublish = () => {
    navigator('/create-resource/publish?resource-id=' + resourceId);
  };

  const isValidUserToken = useCallback(async () => {
    const { error } = await fetch(serverUrl + '/token/info', {
      method: 'POST',
    });
    return error == null;
  }, [fetch]);

  const userTokenAndResourceIdMatched = useCallback(
    async (resourceId) => {
      const { error } = await fetch(serverUrl + '/resource/user-id?resourceId=' + resourceId, {
        method: 'GET',
      });
      return { isSucceed: error == null, error: error == null ? '' : error };
    },
    [fetch]
  );

  const loadResourceInfo = useCallback(async () => {
    const { data, error } = await fetch(serverUrl + '/resource/basic-info?resourceId=' + resourceId);

    if (error !== null) {
      setError(error);
    } else {
      setError('');

      setResourceTitle(data.resourceName);
      setLatestUploadDateTime(moment.utc(data.latestSavedDateTime).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'));
      setMultiChoiceProvided(data.multiChoiceProvided);
      setMultiChoiceAnswerProvided(data.multiChoiceAnswerProvided);
      setMemorizationProvided(data.memorizationProvided);
      setConfusingItemsProvided(data.confusingItemsProvided);
      setVoiceProvided(data.voiceProvided);
      if (data.voiceProvided) {
        setVoiceNameList([...data.voiceNameList]);
        setVoiceOssUrls([...Array(data.voiceNameList.length).fill('')]);
      }
      setCorrectAnswerGroupProvided(data.correctAnswerGroupProvided);
    }
  }, [fetch, resourceId]);

  const loadResourceContent = useCallback(async () => {
    const { data, error } = await fetch(serverUrl + '/resource/content?resourceId=' + resourceId);
    if (error !== null) {
      setError(error);
      return;
    }

    if (data == null) {
      return;
    }

    setError('');

    db.resourceContents.bulkAdd(data);
    setContentListItem(data);

    setSavedItemCount(await db.resourceContents.where('contentFlag').notEqual(2).count());
    setUploadedItemCount(await db.resourceContents.where({ contentFlag: 3 }).count());
  }, [fetch, resourceId]);

  const uploadVoiceToOss = (e, index) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    let base64 = '';
    reader.onloadend = async () => {
      base64 = reader.result.split(',')[1];

      const voiceRequest = {
        fileBytes: base64,
        fileName: uuidv4() + '|' + file.name,
      };

      const { data: voiceOssUrl } = await fetch(serverUrl + '/common/upload-resource-item-voice', {
        method: 'POST',
        body: JSON.stringify(voiceRequest),
      });

      const updatedVoiceOssUrls = [...voiceOssUrls];
      updatedVoiceOssUrls[index] = voiceOssUrl;
      setVoiceOssUrls(updatedVoiceOssUrls);
    };

    reader.readAsDataURL(file);
  };

  const save = async () => {
    setLoadingButton(0);

    const { isAllFilled, element } = isAllRequiredContentFilled();
    if (!isAllFilled) {
      alert(element + '不能为空哦！');

      await new Promise((resolve) => setTimeout(resolve, 0)); // 引入微任务，避免执行下一行代码时页面刷新并丢失 URL 参数
      setLoadingButton(-1);
      return;
    }

    const existedItemsCount = await db.resourceContents.where({ title: title }).count();
    if (existedItemsCount !== 0 && editStatus === 'add') {
      alert(title + ' 这个题目已经存在了');

      await new Promise((resolve) => setTimeout(resolve, 0)); // 引入微任务，避免执行下一行代码时页面刷新并丢失 URL 参数
      setLoadingButton(-1);
      return;
    }

    let contentFlag = '';
    switch (editStatus) {
      case 'add':
        contentFlag = 0;
        break;
      case 'edit':
        contentFlag = 1;
        break;
      case 'delete':
        contentFlag = 2;
        break;
      default:
        contentFlag = 0;
    }

    const currentItem = {
      title: title,
      voiceOssUrls: voiceOssUrls,
      answer: answer,
      rememberWay: memorization,
      confusingItems: confusingItems,
      confusingItemsAnswers: confusingItemsAnswers,
      remark: remark,
      multiChoiceItems: multiChoiceItems,
      multiChoiceItemsAnswer: multiChoiceItemsAnswer,
      correctAnswers: correctAnswers,
      contentFlag: contentFlag,
    };

    switch (editStatus) {
      case 'add':
        await db.resourceContents.add(currentItem);
        setSavedItemCount(await db.resourceContents.where('contentFlag').notEqual(2).count());
        setContentListItem([...contentListItem, currentItem]);
        break;
      case 'edit':
        await db.resourceContents.put(currentItem);
        const updatedContentListItem = contentListItem.map((item) => {
          if (item.title === title) {
            return currentItem;
          }
          return item;
        });
        setContentListItem([...updatedContentListItem]);
        break;
      default:
        break;
    }

    setEditStatus('add');
    clearAllContents();

    await new Promise((resolve) => setTimeout(resolve, 0)); // 引入微任务，避免执行下一行代码时页面刷新并丢失 URL 参数
    setLoadingButton(-1);
  };

  const upload = async () => {
    setLoadingButton(1);

    if (!isAllEmpty) {
      alert('检测到您有正在编辑尚未保存的数据。本次仅上传已保存的项目哦。');
    }

    const contents = await db.resourceContents.toArray();
    const request = {
      resourceId: resourceId,
      content: contents,
    };
    const { error } = await fetch(serverUrl + '/resource/update-resource-content', {
      method: 'POST',
      body: JSON.stringify(request),
    });

    if (error !== null) {
      alert(error);
      setLoadingButton(-1);
      return;
    }

    for (let i = 0; i < contents.length; i++) {
      const content = contents[i];
      if (content.contentFlag !== 2) {
        content.contentFlag = 3;
        await db.resourceContents.put(content);
      } else {
        await db.resourceContents.delete(content.title);
      }
    }

    setSavedItemCount(await db.resourceContents.where('contentFlag').notEqual(2).count());
    setUploadedItemCount(await db.resourceContents.where({ contentFlag: 3 }).count());
    setLatestUploadDateTime(moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'));

    await new Promise((resolve) => setTimeout(resolve, 0)); // 引入微任务，避免执行下一行代码时页面刷新并丢失 URL 参数
    setLoadingButton(-1);
    return;
  };

  const deleteAsync = async () => {
    if (window.confirm(`确定要删除当前题目为${title}的项目吗？`)) {
      setLoadingButton(3);

      const currentItem = {
        title: title,
        voiceOssUrls: voiceOssUrls,
        answer: answer,
        rememberWay: memorization,
        confusingItems: confusingItems,
        confusingItemsAnswers: confusingItemsAnswers,
        remark: remark,
        multiChoiceItems: multiChoiceItems,
        multiChoiceItemsAnswer: multiChoiceItemsAnswer,
        correctAnswers: correctAnswers,
        contentFlag: 2,
      };

      await db.resourceContents.put(currentItem);
      setSavedItemCount(await db.resourceContents.where('contentFlag').notEqual(2).count());
      const updatedContentListItem = contentListItem.map((item) => {
        if (item.title === title) {
          return currentItem;
        }
        return item;
      });
      setContentListItem([...updatedContentListItem]);

      setEditStatus('add');
      clearAllContents();

      await new Promise((resolve) => setTimeout(resolve, 0)); // 引入微任务，避免执行下一行代码时页面刷新并丢失 URL 参数
      setLoadingButton(-1);
    }
  };

  const publish = () => {
    if (window.confirm('确定要发布了吗？接下来我们会配置一些发布选项。')) {
      goPublish();
    }
  };

  const isAllRequiredContentFilled = () => {
    if (title.trim() === '') {
      return { isAllFilled: false, element: '题目' };
    }

    if (voiceProvided && voiceOssUrls.filter((item) => item !== '').length !== voiceNameList.length) {
      return { isAllFilled: false, element: '每个音频都' };
    }

    if (answer.trim() === '') {
      return { isAllFilled: false, element: '答案' };
    }

    if (confusingItemsProvided && confusingItems.filter((item) => item.trim() === '').length !== 0) {
      return { isAllFilled: false, element: '每个易混项都' };
    }

    if (confusingItemsProvided && confusingItemsAnswers.filter((item) => item.trim() === '').length !== 0) {
      return { isAllFilled: false, element: '每个易混项的答案都' };
    }

    if (multiChoiceProvided && multiChoiceItems.filter((item) => item.trim() === '').length !== 0) {
      return { isAllFilled: false, element: '每个错误答案选项组都' };
    }

    if (multiChoiceAnswerProvided && multiChoiceItemsAnswer.filter((item) => item.trim() === '').length !== 0) {
      return { isAllFilled: false, element: '每个错误答案选项组的答案都' };
    }

    return { isAllFilled: true, element: '' };
  };

  const isAllEmpty = () => {
    return (
      title.trim() === '' &&
      voiceOssUrls.filter((item) => item !== '').length === 0 &&
      answer.trim() === '' &&
      memorization.trim() === '' &&
      confusingItems.filter((item) => item.trim() === '').length !== 0 &&
      confusingItemsAnswers.filter((item) => item.trim() === '').length !== 0 &&
      multiChoiceItems.filter((item) => item.trim() === '').length !== 0 &&
      multiChoiceItemsAnswer.filter((item) => item.trim() === '').length !== 0 &&
      correctAnswers.filter((item) => item.trim() === '').length !== 0 &&
      remark.trim() === ''
    );
  };

  const clearAllContents = () => {
    setTitle('');
    setVoiceOssUrls([]);
    voiceRefs.current.forEach((voice) => {
      if (voice) {
        voice.value = '';
      }
    });
    setAnswer('');
    setMemorization('');
    setConfusingItemsCount(1);
    setConfusingItems(() => ['']);
    setConfusingItemsAnswers(() => ['']);
    setMultiChoiceItemsCount(1);
    setMultiChoiceItems(() => ['']);
    setMultiChoiceItemsAnswer(() => ['']);
    setCorrectAnswersCount(1);
    setCorrectAnswers(() => ['']);
    setRemark('');
  };

  const loadSavedItem = (item) => {
    if (!isAllEmpty() && !window.confirm('检测到您有正在编辑尚未保存的数据，要丢弃吗？')) {
      return;
    }

    clearAllContents();
    setEditStatus('edit');

    setTitle(item.title);
    if (item.voiceOssUrls.length !== 0) {
      setVoiceOssUrls([...item.voiceOssUrls]);
    }
    setAnswer(item.answer);
    if (memorizationProvided) {
      setMemorization(item.rememberWay);
    }
    if (confusingItemsProvided) {
      setConfusingItems([...item.confusingItems]);
      setConfusingItemsAnswers([...item.confusingItemsAnswers]);
    }
    if (multiChoiceProvided) {
      setMultiChoiceItems([...item.multiChoiceItems]);
    }
    if (multiChoiceAnswerProvided) {
      setMultiChoiceItemsAnswer([...item.multiChoiceItemsAnswer]);
    }
    if (correctAnswerGroupProvided) {
      setCorrectAnswers([...item.correctAnswers]);
    }
    setRemark(item.remark);
  };

  useEffect(() => {
    const checkData = async () => {
      if (resourceId == null) {
        setError('参数丢失，未找到资源编号。');
      } else if (!uuidValidate(resourceId)) {
        setError('参数错误，资源编号格式错误。');
      } else if (user == null) {
        navigator('/login?back-to=/create-resource/content?resource-id=' + resourceId);
      } else if (!isValidUserToken(user.token)) {
        navigator('/login?back-to=/create-resource/content?resource-id=' + resourceId);
      } else {
        const { error } = await userTokenAndResourceIdMatched(resourceId);
        if (error !== '') {
          setError(error);
        } else {
          setError('');
          db.resourceContents.clear();
          await loadResourceInfo();
          await loadResourceContent();
        }
      }
    };

    setTimeZoneString(moment.tz.guess());
    checkData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="cyan-background-container">
      {error === '' && (
        <div className={style.splitContainer}>
          <div className={style.leftContainer}>
            <h3>{resourceTitle}</h3>
            <div>当前已保存 {savedItemCount} 个资源。</div>
            <div>当前已上传 {uploadedItemCount} 个资源。</div>
            <div>
              <label>上次上传时间：</label>
              <label>{latestUploadDateTime}</label>
            </div>
            <div>您的时区：{timeZoneString}</div>
            <ModelDialog
              id="incorrectTimeZoneModel"
              label="时区不正确？"
              title="显示的时区不是我的真实时区？"
              content="无需担心！
              <br />
              <br />
              这里的时区仅仅会影响到“上次上传时间”的显示。所有操作的时间我们都会记录为标准世界时间（UTC）。
              <br />
              <br />
              如您想让这里根据您所在的时区显示正确的时间，请检查您设备的系统设置中的地区与时间设置，或是浏览器的时区设置。"
            />
            <div className={style.contentListContainer}>
              <ul className="list-unstyled">
                {contentListItem.map((item) => {
                  if (item.contentFlag !== 2) {
                    return (
                      <li
                        key={item.title}
                        className={style.contentListItem}
                        onClick={() => {
                          loadSavedItem(item);
                        }}
                      >
                        {item.title}
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          </div>
          <div className={style.rightContainer}>
            <form>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  题目
                </label>
                <input className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>

              {voiceProvided && (
                <div className="mb-3">
                  {voiceNameList.map((voiceName, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <label>{voiceName}</label>
                          <label
                            key={`play-button-${index}`}
                            className="ms-2"
                            style={{ cursor: 'pointer' }}
                            hidden={voiceOssUrls[index] === ''}
                            onClick={(e) => {
                              e.preventDefault();
                              new Audio(voiceOssUrls[index]).play();
                            }}
                          >
                            播放▶
                          </label>
                          <label key={`voice-name-${index}`} className="ms-2">
                            {editStatus === 'edit' && '已上传音频：' + voiceOssUrls[index].split('|', 2)[1]}
                          </label>
                        </div>
                        <input
                          key={`voice-input-${index}`}
                          className="form-control mb-2"
                          type="file"
                          accept=".aac"
                          ref={(voice) => {
                            voiceRefs.current[index] = voice;
                          }}
                          onChange={(e) => {
                            uploadVoiceToOss(e, index);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="mb-3">
                <label htmlFor="answer" className="form-label">
                  答案
                </label>
                <textarea
                  className="form-control"
                  id="answer"
                  rows="4"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
              </div>

              {memorizationProvided && (
                <div className="mb-3">
                  <label htmlFor="memorization" className="form-label">
                    记忆方法
                  </label>
                  <textarea
                    className="form-control"
                    id="memorization"
                    rows="4"
                    placeholder="有什么好用的甚至抖机灵的记忆方法吗？写在这里！"
                    value={memorization}
                    onChange={(e) => setMemorization(e.target.value)}
                  />
                </div>
              )}

              {confusingItemsProvided && (
                <div className="mb-3">
                  <label htmlFor="confusingItems" className="form-label">
                    易混项
                  </label>
                  {[...Array(confusingItemsCount)].map((_, index) => {
                    return (
                      <div className="input-group" id={`confusingItems-${index}`} key={index}>
                        <input
                          key={`confusingItemsTitle-${index}`}
                          className="form-control"
                          placeholder="题目"
                          value={confusingItems[index]}
                          onChange={(e) => {
                            const updatedItems = [...confusingItems];
                            updatedItems[index] = e.target.value;
                            setConfusingItems(updatedItems);
                          }}
                        />
                        <input
                          key={`confusingItemsAnswer-${index}`}
                          className="form-control"
                          placeholder="答案"
                          value={confusingItemsAnswers[index]}
                          onChange={(e) => {
                            const updatedItems = [...confusingItemsAnswers];
                            updatedItems[index] = e.target.value;
                            setConfusingItemsAnswers(updatedItems);
                          }}
                        />
                        <button
                          className="input-group-text"
                          onClick={(event) => {
                            event.preventDefault();
                            setConfusingItemsCount(confusingItemsCount + 1);
                          }}
                        >
                          +
                        </button>
                        <button
                          className="input-group-text"
                          disabled={confusingItemsCount === 1}
                          onClick={(event) => {
                            event.preventDefault();

                            const updatedItems = [...confusingItems];
                            updatedItems.splice(index, 1);
                            setConfusingItems(updatedItems);

                            const updatedItemsAnswer = [...confusingItemsAnswers];
                            updatedItemsAnswer.splice(index, 1);
                            setConfusingItemsAnswers(updatedItemsAnswer);

                            setConfusingItemsCount(confusingItemsCount - 1);
                          }}
                        >
                          -
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}

              {multiChoiceProvided && (
                <div className="mb-3">
                  <label htmlFor="multiChoice" className="form-label">
                    错误答案选项组
                  </label>
                  {[...Array(multiChoiceItemsCount)].map((_, index) => {
                    return (
                      <div className="input-group" id={`multiChoiceItems-${index}`} key={index}>
                        <input
                          key={`multiChoiceItemTitle-${index}`}
                          className="form-control"
                          placeholder="干扰项答案"
                          value={multiChoiceItems[index]}
                          onChange={(e) => {
                            const updatedItems = [...multiChoiceItems];
                            updatedItems[index] = e.target.value;
                            setMultiChoiceItems(updatedItems);
                          }}
                        />
                        {multiChoiceAnswerProvided && (
                          <input
                            key={`multiChoiceItemAnswer-${index}`}
                            className="form-control"
                            placeholder="干扰项的正确题目"
                            value={multiChoiceItemsAnswer[index]}
                            onChange={(e) => {
                              const updatedItems = [...multiChoiceItemsAnswer];
                              updatedItems[index] = e.target.value;
                              setMultiChoiceItemsAnswer(updatedItems);
                            }}
                          />
                        )}
                        <button
                          className="input-group-text"
                          onClick={(event) => {
                            event.preventDefault();
                            setMultiChoiceItemsCount(multiChoiceItemsCount + 1);
                          }}
                        >
                          +
                        </button>
                        <button
                          className="input-group-text"
                          disabled={multiChoiceItemsCount === 1}
                          onClick={(event) => {
                            event.preventDefault();

                            const updatedItems = [...multiChoiceItems];
                            updatedItems.splice(index, 1);
                            setMultiChoiceItems(updatedItems);

                            if (multiChoiceAnswerProvided) {
                              const updatedItemsAnswer = [...multiChoiceItemsAnswer];
                              updatedItemsAnswer.splice(index, 1);
                              setMultiChoiceItemsAnswer(updatedItemsAnswer);
                            }

                            setMultiChoiceItemsCount(multiChoiceItemsCount - 1);
                          }}
                        >
                          -
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}

              {correctAnswerGroupProvided && (
                <div className="mb-3">
                  <label htmlFor="correctAnswerGroup" className="form-label">
                    可接受的正确答案组
                  </label>
                  {[...Array(correctAnswersCount)].map((_, index) => {
                    return (
                      <div className="input-group" id={`correctAnswerGroup-${index}`} key={index}>
                        <input
                          key={`correctAnswerGroup-${index}`}
                          className="form-control"
                          placeholder="用户拼写时写成这里的内容，将被视为拼写正确"
                          value={correctAnswers[index]}
                          onChange={(e) => {
                            const updatedItems = [...correctAnswers];
                            updatedItems[index] = e.target.value;
                            setCorrectAnswers(updatedItems);
                          }}
                        />
                        <button
                          className="input-group-text"
                          onClick={(event) => {
                            event.preventDefault();
                            setCorrectAnswersCount(correctAnswersCount + 1);
                          }}
                        >
                          +
                        </button>
                        <button
                          className="input-group-text"
                          disabled={correctAnswersCount === 1}
                          onClick={(event) => {
                            event.preventDefault();

                            const updatedItems = [...correctAnswers];
                            updatedItems.splice(index, 1);
                            setCorrectAnswers(updatedItems);

                            setCorrectAnswersCount(correctAnswersCount - 1);
                          }}
                        >
                          -
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="mb-3">
                <label htmlFor="remark" className="form-label">
                  备注
                </label>
                <textarea
                  className="form-control"
                  id="remark"
                  rows="4"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>

              <div className={style.actions}>
                <div
                  className={
                    loadingButton === -1
                      ? 'rounded-button rounded-button-border'
                      : 'disabled-button rounded-button-border'
                  }
                  disabled={loadingButton !== -1}
                  onClick={save}
                >
                  {loadingButton === 0 ? '保存中……' : '保存，下一个'}
                </div>
                <div
                  className={
                    loadingButton === -1
                      ? 'rounded-button rounded-button-border'
                      : 'disabled-button rounded-button-border'
                  }
                  disabled={loadingButton !== -1}
                  onClick={upload}
                >
                  {loadingButton === 1 ? '上传中……' : '上传'}
                </div>
                <div
                  className={
                    loadingButton === -1
                      ? 'rounded-button rounded-button-border'
                      : 'disabled-button rounded-button-border'
                  }
                  disabled={loadingButton !== -1}
                  onClick={publish}
                >
                  现在发布
                </div>
              </div>
              {editStatus === 'edit' && (
                <div className={`${style.deleteAction} mt-3`}>
                  <div
                    className={
                      loadingButton === -1
                        ? 'rounded-button alert-button rounded-button-border'
                        : 'disabled-button rounded-button-border'
                    }
                    disabled={loadingButton !== -1}
                    onClick={deleteAsync}
                  >
                    {loadingButton === 3 ? '删除中……' : '删除该项目'}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
      {error !== '' && (
        <div style={{ textAlign: 'center' }}>
          <h2>{error}</h2>
          <button className="rounded-button rounded-button-border" onClick={goHome}>
            返回首页
          </button>
        </div>
      )}
    </div>
  );
}
