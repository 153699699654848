import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import logoImg from './logo.png'
import './index.scss'

function NavBar({user}) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <Link to="/">
          <img src={logoImg} alt="logo" height={'40px'} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <div className="me-auto" />
          <form className="d-flex">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/resources" className="nav-link ms-4">
                包含资源
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/create-resource" className="nav-link ms-4" area-current='page'>
                创建资源
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/privacy-policy" className="nav-link ms-4">
                隐私政策
              </Link>
            </li>
            <li className="nav-item">
              {user === null && <Link to="/login" className="nav-link ms-4">登录</Link>}
              {user !== null && <Link to="/user-center" className="nav-link ms-4">{user.nickname}</Link>}
            </li>
          </ul>
          </form>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, {})(NavBar);
