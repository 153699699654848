import React from 'react'
import style from './index.module.scss'
import backgroundImage from './background-image.jpg';
import iconImage from './app-icon.jpg'

export default function Slogan() {
  return (
    <div className={style.container}>
      <img src={backgroundImage} alt="Background" className={style.image} />
      <div>
        <img className={style.icon} src={iconImage} alt='icon' />
        <p className={style.title}>很好记</p>
        <p className={style.subtitle}>一个精巧又自由的艾宾浩斯记忆助手。</p>
      </div>
    </div>
  )
}
